import React from "react"
import { from, interval, concat, of } from "rxjs"
import {
  map,
  take,
  repeat,
  delay,
  concatMap,
  ignoreElements,
} from "rxjs/operators"
import { useObservable } from "rxjs-hooks"

const TypedTitle = () => {
  const type = ({ word, speed, backwards = false }) =>
    interval(speed).pipe(
      map(x =>
        backwards ? word.substr(0, word.length - x - 1) : word.substr(0, x + 1)
      ),
      take(word.length)
    )

  const typeEffect = word =>
    concat(
      type({ word, speed: 50 }), // type forwards
      of("").pipe(delay(1200), ignoreElements()), // pause
      type({ word, speed: 30, backwards: true }), // delete
      of("").pipe(delay(300), ignoreElements()) // pause
    )

  const titles = ["GLOBAL.", "SIMPLE.", "CONNECTED.", "FAST.", "EXPONENTIAL."]

  const value = useObservable(() =>
    from(titles).pipe(concatMap(typeEffect), repeat())
  )

  return (
    <span className="font-medium border-r border-solid border-gray-800">
      {value}
    </span>
  )
}

export default TypedTitle
