import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import Features from "../components/Features"
import ContactForm from "../components/ContactForm"
import TypedTitle from "../components/TypedTitle"
// import Testimonials from "../components/Testimonials"

export default function Home() {
  return (
    <Layout>
      <div className="flex flex-col h-full px-10 mx-auto space-y-6 md:pl-10 lg:h-screen md:mb-20 md:flex-row md:items-center md:space-x-6 md:bg-hero-img md:bg-no-repeat md:bg-cover md:bg-contain md:bg-right">
        <div className="flex flex-col items-center w-full md:flex-row">
          <div className="py-32 mt-5 md:mx-12 md:order-2">
            <h1 className="mt-5 mb-10 text-3xl tracking-wide text-center text-gray-800 lowercase md:text-left md:text-4xl lg:text-7xl">
              <span className="font-bold">BE</span> <TypedTitle />
            </h1>
            <div className="flex flex-col justify-center max-w-lg md:justify-start">
              <h4 className="mt-4 text-md md:text-lg text-black-600">
                Toda a gestão comercial, operacional, financeira e
                administrativa para operadores logísticos em um único software.
              </h4>
              <h4 className="mt-4 text-md md:text-lg text-black-600">
                Desenvolvido com o que o mercado tem de melhor em termos de
                tecnologia.
              </h4>
            </div>

            <div className="flex justify-center w-full mt-10 md:justify-start">
              <Link
                to="/contact/"
                className="block px-3 py-3 text-sm font-semibold text-center text-white uppercase transition-colors duration-200 transform rounded-md md:text-md bg-orange md:inline hover:bg-orange-light"
              >
                Solicite uma Demonstração
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="py-12 bg-black md:pt-0 h-max-screen md:h-64 lg:mt-12">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex flex-col items-center justify-center space-y-10 md:flex-row md:space-y-0 md:grid md:grid-cols-4 md:gap-x-8 md:gap-y-10">
            <div className="md:relative">
              <div className="flex flex-col items-center justify-center md:absolute md:-top-12">
                <div className="flex flex-col items-center justify-center w-24 h-24 bg-white border rounded-full text-orange border-orange">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-16 h-16"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <p className="mt-2 text-lg font-bold leading-6 text-center text-white">
                  Intuitivo
                </p>
                <p className="mt-2 text-base text-center text-white md:px-2 lg:px-8">
                  Design simples e moderno, com foco na experiência do usuário.
                </p>
              </div>
            </div>

            <div className="md:relative">
              <div className="flex flex-col items-center justify-center md:absolute md:-top-12">
                <div className="flex flex-col items-center justify-center w-24 h-24 bg-white border rounded-full text-orange border-orange">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-16 h-16"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </div>
                <p className="mt-2 text-lg font-bold leading-6 text-center text-white">
                  Integrado
                </p>
                <p className="mt-2 text-base text-center text-white md:px-2 lg:px-8">
                  Integração total entre todos setores da empresa, e relatórios
                  eficientes para realizar a gestão.
                </p>
              </div>
            </div>

            <div className="md:relative">
              <div className="flex flex-col items-center justify-center md:absolute md:-top-12">
                <div className="flex flex-col items-center justify-center w-24 h-24 bg-white border rounded-full text-orange border-orange">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-16 h-16"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    />
                  </svg>
                </div>
                <p className="mt-2 text-lg font-bold leading-6 text-center text-white">
                  Web
                </p>
                <p className="mt-2 text-base text-center text-white md:px-2 lg:px-8">
                  Arquitetura na nuvem, permitindo uma maior segurança e
                  performance no tráfego dos dados.
                </p>
              </div>
            </div>

            <div className="md:relative">
              <div className="flex flex-col items-center justify-center md:absolute md:-top-12">
                <div className="flex flex-col items-center justify-center w-24 h-24 bg-white border rounded-full text-orange border-orange">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-16 h-16"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                    />
                  </svg>
                </div>
                <p className="mt-2 text-lg font-bold leading-6 text-center text-white">
                  Global
                </p>
                <p className="mt-2 text-base text-center text-white md:px-2 lg:px-8">
                  Disponível em três idiomas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Features />

      {/* <Testimonials /> */}

      <ContactForm />
    </Layout>
  )
}
