import React, { useState } from "react"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

export default function Features() {
  const [activeStatus, setActiveStatus] = useState(1)
  return (
    <div class="min-w-screen flex items-center justify-center pt-5 md:pt-32">
      <div class="w-full">
        <div class="flex flex-col md:flex-row max-w-6xl mx-auto items-center mb-5 md:mb-32">
          <div class="w-full md:w-1/2 flex flex-col p-8 items-center justify-center">
            <h1 className="text-5xl md:text-6xl lg:text-8xl leading-normal mt-0 md:mb-2 text-black font-bold">
              nossas
            </h1>
            <h1 className="text-5xl md:text-6xl lg:text-7xl leading-normal mt-0 text-black">
              soluções
            </h1>
          </div>
          <div class="w-full md:w-1/2 mx-auto p-5 md:py-5 p-3">
            <h3 className="text-lg md:text-2xl font-normal leading-normal text-black max-w-xl mx-auto text-center">
              Desenvolvemos nossas ferramentas com{" "}
              <span className="font-bold">
                foco na gestão de todos os dados relacionados à operação da
                empresa
              </span>
              , desde a negociação de um serviço até a movimentação financeira.
              Conheça as principais características dos nossos produtos.
            </h3>
          </div>
        </div>
        <div class="max-w-6xl mx-auto">
          <div className="xl:w-full xl:mx-0 h-12 sm:block flex flex-wrap items-center">
            <ul className="flex px-2 md:px-5 w-full justify-between md:justify-center">
              <li
                className={
                  activeStatus === 1
                    ? "text-base md:text-2xl p-3 rounded-t-lg text-white bg-orange w-1/2 text-center"
                    : "text-base md:text-2xl p-3 text-black cursor-pointer w-1/2 text-center"
                }
              >
                <button type="button" onClick={() => setActiveStatus(1)}>
                  <div className="flex items-center mb-3">
                    <span className="font-bold">bewex</span>
                    <span>Cargo</span>
                  </div>
                </button>
              </li>
              <li
                className={
                  activeStatus === 2
                    ? "text-base md:text-2xl p-3 rounded-t-lg text-white bg-orange w-1/2 text-center"
                    : "text-base md:text-2xl p-3 text-black cursor-pointer w-1/2 text-center"
                }
              >
                <button type="button" onClick={() => setActiveStatus(2)}>
                  <div className="flex items-center mb-3">
                    <span className="font-bold">bewex</span>
                    <span>Customs</span>
                  </div>
                </button>
              </li>
              {/* <li
                className={
                  activeStatus === 3
                    ? "text-base md:text-2xl p-3 rounded-t-lg text-white bg-orange w-1/2 text-center"
                    : "text-base md:text-2xl p-3 text-black cursor-pointer w-1/2 text-center"
                }
              >
                <button type="button" onClick={() => setActiveStatus(3)}>
                  <div className="flex items-center mb-3">
                    <span className="font-bold">Tracking</span>
                  </div>
                </button>
              </li> */}
            </ul>
          </div>
        </div>

        {activeStatus === 1 ? (
          <div className="w-full h-auto px-6 py-12 md:px-12 bg-black mt-3 md:mt-5">
            <div class="w-full md:max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-center">
              <div className="w-full md:w-1/3 flex items-center justify-center">
                <StaticImage
                  src="../../images/air-plane.svg"
                  alt="frete-avião-carga"
                  layout="fixed"
                  width={200}
                />
              </div>
              <div className="flex flex-wrap flex-col w-full p-4 md:px-4 md:w-2/3">
                <p className="text-white py-3">
                  Software para a gestão de toda a empresa. Dispõe de área
                  comercial com geração e emissão de cotações, área operacional
                  com controle de embarques aéreos, marítimos, e rodoviário
                  internacional, e área financeira com geração de contas à
                  receber, contas à pagar, extrato bancário, entre outros
                  controles.
                </p>
                <p className="text-white py-3">
                  Com dados de todos os setores e escritórios integrados, e
                  diversas opções de relatórios, com opções de exibição de
                  valores em moeda local e moeda estrangeira.
                </p>

                <div className="w-full py-5 flex items-center justify-center md:justify-start">
                  <Link
                    to="/solutions/"
                    className="px-4 py-3 text-sm md:text-md font-semibold text-center text-white uppercase transition-colors duration-200 transform bg-orange rounded-md hover:bg-blue-500"
                  >
                    Ver mais
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {activeStatus === 2 ? (
          <div className="w-full h-auto px-6 py-12 md:px-12 bg-black md:mt-5">
            <div class="w-full md:max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-center">
              <div className="w-full md:w-1/3 flex items-center justify-center">
                <StaticImage
                  src="../../images/customs-clearance.svg"
                  alt="despacho-aduaneiro"
                  layout="fixed"
                  width={200}
                />
              </div>
              <div className="flex flex-wrap flex-col w-full p-4 md:px-4 md:w-2/3">
                <p className="text-white py-3">
                  Sistema de gerenciamento de despacho aduaneiro, com emissão de
                  documentos (DI / DUE / NF) integrada com Siscomex e Sefaz.
                </p>
                <p className="text-white py-3">
                  Conta também com gestão de processos de importação e
                  exportação temporárias, com emissões de DSI/DSE e integração
                  entre processos de importação e exportação.
                </p>

                <div className="w-full py-5 flex items-center justify-center md:justify-start">
                  <Link
                    to="/solutions/"
                    className="px-4 py-3 text-sm md:text-md font-semibold text-center text-white uppercase transition-colors duration-200 transform bg-orange rounded-md hover:bg-blue-500"
                  >
                    Ver mais
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* {activeStatus === 3 ? (
          <div className="w-full h-auto px-6 py-12 md:px-12 bg-slate-100 md:mt-5">
            <div class="w-full md:max-w-6xl mx-auto flex items-center justify-center">
              <div className="w-full md:w-1/3 flex items-center justify-center">
                <StaticImage
                  src="../../images/tracking.svg"
                  alt="tracking-trace"
                  layout="fixed"
                  width={200}
                />
              </div>
              <div className="flex flex-wrap w-full px-4 md:w-2/3">
                <ul>
                  <li className="text-gray-700">EM BREVE</li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          ""
        )} */}
      </div>
    </div>
  )
}
